import { gql } from "@apollo/client";
import { EMessageType, IMessage, IMessageComment } from "types/Message.type";

export interface ICreateMessageInput {
  type: EMessageType;
  text?: string;
  questionName?: string;
  questionOptions?: string[];
  skill?: string;
  thumbnail?: string;
  tags?: string[];
}

const CREATE_MESSAGE = gql`
  mutation createMessage($createMessageInput: CreateMessageInput!) {
    createMessage(createMessageInput: $createMessageInput) {
      id
    }
  }
`;

export interface ICreateMessageCommentInput {
  message: string;
  text: string;
}

const CREATE_MESSAGE_COMMENT = gql`
  mutation createMessageComment(
    $createMessageCommentInput: CreateMessageCommentInput!
  ) {
    createMessageComment(
      createMessageCommentInput: $createMessageCommentInput
    ) {
      id
      message
      text
      createdAt
      createdBy {
        id
        name
        lastname
        profileImage
      }
      reactions {
        emoji
        user {
          name
          lastname
          id
        }
      }
    }
  }
`;

export interface IMessageReactionInput {
  message: string;
  emoji: string;
}

const MESSAGE_REACTION = gql`
  mutation messageReaction($messageReactionInput: MessageReactionInput!) {
    messageReaction(messageReactionInput: $messageReactionInput) {
      emoji
      user {
        name
        lastname
        id
        profileImage
        email
      }
    }
  }
`;

export interface ICommentMessageReactionInput {
  commentMessage: string;
  emoji: string;
}

const COMMENT_MESSAGE_REACTION = gql`
  mutation commentMessageReaction(
    $commentMessageReactionInput: CommentMessageReactionInput!
  ) {
    commentMessageReaction(
      commentMessageReactionInput: $commentMessageReactionInput
    ) {
      emoji
      user {
        name
        lastname
        id
        profileImage
        email
      }
    }
  }
`;

export interface IMessagePollAnswerInput {
  message: string;
  index: number;
}

const MESSAGE_POLL_ANSWER = gql`
  mutation messagePollAnswer($messagePollAnswerInput: MessagePollAnswerInput!) {
    messagePollAnswer(messagePollAnswerInput: $messagePollAnswerInput) {
      user
      index
    }
  }
`;

const DELETE_MESSASGE = gql`
  mutation DeleteMessage($message: String!) {
    deleteMessage(message: $message) {
      id
    }
  }
`;

const DELETE_MESSAGE_COMMENT = gql`
  mutation DeleteMessageComment($messageComment: String!) {
    deleteMessageComment(messageComment: $messageComment) {
      id
    }
  }
`;

export interface IUpdateMessageInput {
  id: string;
  text: string;
  thumbnail?: string;
  tags?: string[];
}

export interface IUpdateMessageRes {
  updateMessage: IMessage;
}

const UPDATE_MESSAGE = gql`
  mutation updateMessage($updateMessageInput: UpdateMessageInput!) {
    updateMessage(updateMessageInput: $updateMessageInput) {
      id
      edited
      isVoted
      createdAt
      updatedAt
      type
      text
      question
      thumbnail
      questionOptions
      createdBy {
        id
        name
        lastname
        profileImage
      }
      answer {
        id
        text
        votes
      }
      comments {
        id
        edited
        text
        createdAt
        updatedAt
        message
        createdBy {
          id
          name
          lastname
          profileImage
        }
        reactions {
          emoji
          user {
            name
            lastname
            id
            profileImage
            role {
              name
            }
          }
        }
      }
      tags
      reactions {
        emoji
        user {
          name
          lastname
          id
        }
      }
    }
  }
`;

export interface IUpdateMessageCommentInput {
  id: string;
  text: string;
}

export interface IUpdateMessageCommentRes {
  updateMessageComment: IMessageComment;
}

const UPDATE_MESSAGE_COMMENT = gql`
  mutation UpdateMessageComment(
    $updateMessageCommentInput: UpdateMessageCommentInput!
  ) {
    updateMessageComment(
      updateMessageCommentInput: $updateMessageCommentInput
    ) {
      id
      text
      createdAt
      updatedAt
      message
      createdBy {
        id
        name
        lastname
        profileImage
      }
      reactions {
        emoji
        user {
          name
          lastname
          id
          profileImage
          role {
            name
          }
        }
      }
    }
  }
`;

const MARK_CAPSULE_AS_SEEN = gql`
  mutation AddViewToCapsule($message: String!) {
    addViewToCapsule(message: $message) {
      id
    }
  }
`;

export default {
  CREATE_MESSAGE,
  CREATE_MESSAGE_COMMENT,
  MESSAGE_REACTION,
  MESSAGE_POLL_ANSWER,
  DELETE_MESSASGE,
  DELETE_MESSAGE_COMMENT,
  COMMENT_MESSAGE_REACTION,
  UPDATE_MESSAGE,
  UPDATE_MESSAGE_COMMENT,
  MARK_CAPSULE_AS_SEEN,
};
