const exp = require("constants");
const { min, pad } = require("lodash");

module.exports = {
  content: ["./src/**/*.tsx", "./src/**/*.scss", "./src/**/*.less"],
  darkMode: "class", // or 'media' or 'class'
  prefix: "",
  theme: {
    extend: {
      fontSize: {
        sidebar: "15px",
        xxs: "10px",
        "sidebar-submenu": "13px",
      },
      screens: {
        sm: "640px", // Small screens
        md: "769px", // Medium screens
        lg: "1024px", // Large screens
        is1140: "1140px", // Two Columns on Explorer
        qhd: "1440px", // Two Columns on Explorer
        xl: "1280px", // Extra large screens
        fhd: "1920px", // Full HD screens
        "2xl": "1536px", // 2X large screens
      },
      keyframes: {
        "accordion-down": {
          from: { height: "0" },
          to: { height: "var(--radix-accordion-content-height)" },
        },
        "accordion-up": {
          from: { height: "var(--radix-accordion-content-height)" },
          to: { height: "0" },
        },
        fadeIn: {
          "0%": { opacity: 0 },
          "100%": { opacity: 1 },
        },
        fadeOut: {
          "0%": { opacity: 1 },
          "100%": { opacity: 0 },
        },
        slideInFromRight: {
          "0%": { transform: "translateX(100%)" },
          "100%": { transform: "translateX(0)" },
        },
        slideOutToRight: {
          "0%": { transform: "translateX(0)" },
          "100%": { transform: "translateX(100%)" },
        },
        zoomIn: {
          "0%": { transform: "scale(0.5)" },
          "100%": { transform: "scale(1)" },
        },
        zoomOut: {
          "0%": { transform: "scale(1)" },
          "100%": { transform: "scale(0.5)" },
        },
      },
      animation: {
        "accordion-down": "accordion-down 0.2s ease-out",
        "accordion-up": "accordion-up 0.2s ease-out",
        "fade-in": "fadeIn 0.5s ease-out",
        "fade-out": "fadeOut 0.5s ease-in",
        "slide-in-from-right": "slideInFromRight 0.5s ease-out",
        "slide-out-to-right": "slideOutToRight 0.5s ease-in",
        "zoom-in": "zoomIn 0.5s ease-out",
        "zoom-out": "zoomOut 0.5s ease-in",
      },
      boxShadow: {
        "custom-top": "var(--customTopShadow)",
        "custom-right": "var(--customRightShadow)",
      },
      height: {
        screen: ["-webkit-fill-available", "100vh"],
        "40vh": "40vh",
        300: "300px",
        "banner-lg": "300px",
        "sidebar-header": "79px",
        "sidebar-icon": "28px",
        "sidebar-dropdown-icon": "20px",
        "sidebar-button": "48px",
        "sidebar-dropdown-button": "34px",
      },
      minHeight: {
        "sidebar-icon": "28px",
        "sidebar-dropdown-icon": "20px",
      },
      width: {
        processCard: "256px",
        "sidebar-expanded": "270px",
        "sidebar-collapsed": "80px",
        "sidebar-icon": "28px",
        "sidebar-dropdown-icon": "20px",
      },
      minWidth: {
        "sidebar-icon": "28px",
        "sidebar-dropdown-icon": "20px",
        100: "100px",
        200: "200px",
        300: "300px",
      },
      zIndex: {
        5: 5,
        6: 6,
        7: 7,
        8: 8,
        9: 9,
        10: 10,
        11: 11,
      },
      container: {
        padding: {
          DEFAULT: "12px",
        },
        screens: {
          sm: "680px",
          md: "868px",
          lg: "1200px",
          xl: "1200px",
          "2xl": "1200px",
        },
        center: true,
      },
      maxHeight: {
        0: "0",
        "1/4": "25%",
        "1/2": "50%",
        "3/4": "75%",
        full: "100%",
      },
      flex: {
        "1/4": "0.25",
        "1/2": "0.5",
        "3/4": "0.75",
        1: "1",
      },
      margin: {
        "0!": "0 !important",
      },
      colors: {
        "plan-active": "#4B37F2",
        "plan-inactive": "#C2D7FE",
        portal: "rgba(0, 0, 0, 0.8)",
        navBar: "var(--customBgNavBar)",
        "sidebar-header": "var(--BgSidebarHeader)",
        "sidebar-body": "var(--BgSidebarBody)",
        "sidebar-footer": "var(--BgSidebarFooter)",
        "capsule-background": "var(--BgCapsule)",
        current: {
          100: "var(--rs-primary-100)",
          200: "var(--rs-primary-200)",
          300: "var(--rs-primary-300)",
          400: "var(--rs-primary-400)",
          500: "var(--rs-primary)",
          600: "var(--rs-primary-600)",
          700: "var(--rs-primary-700)",
          800: "var(--rs-primary-800)",
          900: "var(--rs-primary-900)",
        },
        background: "var(--background)",
        black: "var(--rs-black)",
        gray: {
          50: "var(--rs-gray-50)",
          100: "var(--rs-gray-100)",
          200: "var(--rs-gray-200)",
          300: "var(--rs-gray-300)",
          400: "var(--rs-gray-400)",
          500: "var(--rs-gray-500)",
          600: "var(--rs-gray-600)",
          700: "var(--rs-gray-700)",
          800: "var(--rs-gray-800)",
          850: "var(--rs-gray-850)",
          900: "var(--rs-gray-900)",
        },
        button: {
          nav: {
            default: "transparent",
            hover: "var(--button-nav-hover)",
          },
        },
        customGreen: {
          50: "var(--rs-customGreen-50)",
          100: "var(--rs-customGreen-100)",
          200: "var(--rs-customGreen-200)",
          300: "var(--rs-customGreen-300)",
          400: "var(--rs-customGreen-400)",
          500: "var(--rs-customGreen-500)",
          600: "var(--rs-customGreen-600)",
          700: "var(--rs-customGreen-700)",
          800: "var(--rs-customGreen-800)",
          850: "var(--rs-customGreen-850)",
          900: "var(--rs-customGreen-900)",
        },
        customGray: {
          50: "var(--rs-customGray-50)",
          100: "var(--rs-customGray-100)",
          200: "var(--rs-customGray-200)",
          300: "var(--rs-customGray-300)",
          400: "var(--rs-customGray-400)",
          500: "var(--rs-customGray-500)",
          600: "var(--rs-customGray-600)",
          700: "var(--rs-customGray-700)",
          800: "var(--rs-customGray-800)",
          900: "var(--rs-customGray-900)",
        },
        border: "var(--border)",
        input: "var(--input)",
        ring: "var(--ring)",
        background: "var(--background)",
        foreground: "var(--foreground)",
        primary: {
          DEFAULT: "var(--primary)",
          foreground: "var(--primary-foreground)",
        },
        secondary: {
          DEFAULT: "var(--secondary)",
          foreground: "var(--secondary-foreground)",
        },
        destructive: {
          DEFAULT: "var(--destructive)",
          foreground: "var(--destructive-foreground)",
        },
        muted: {
          DEFAULT: "var(--muted)",
          foreground: "var(--muted-foreground)",
        },
        accent: {
          DEFAULT: "var(--accent)",
          foreground: "var(--accent-foreground)",
        },
        popover: {
          DEFAULT: "var(--popover)",
          foreground: "var(--popover-foreground)",
        },
        card: {
          DEFAULT: "var(--card)",
          foreground: "var(--card-foreground)",
        },
        dashboard: {
          background: "var(--customBgDashboard)",
        },
        navBar: {
          background: "var(--customBgNavBar)",
        },
        inactive: {
          icon: "var(--customBgInactiveIcon)",
          button: "var(--customBgInactiveButton)",
        },
        active: {
          icon: "var(--customBgActiveIcon)",
          button: "var(--customBgActiveButton)",
          buttonPrimary: "var(--customBgActiveButtonPrimary)",
        },
      },
      borderRadius: {
        lg: "var(--radius)",
        md: "calc(var(--radius) - 2px)",
        sm: "calc(var(--radius) - 4px)",
      },
    },
  },
  // eslint-disable-next-line global-require
  plugins: [
    require("tailwindcss-animate"),
    // Otros plugins que quieras agregar
  ],
};
