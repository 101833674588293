import dynamic from "next/dynamic";
import { FC, ReactNode } from "react";
import Sidebar from "../Sidebar/Sidebar";

const NavBar = dynamic(() => import("components/NavBar/NavBar"), {
  ssr: false,
});

interface ManagerProLayoutProps {
  children?: ReactNode;
}

const ManagerProLayout: FC<ManagerProLayoutProps> = ({ children }) => {
  return (
    <div
      className="max-w-screen flex h-full w-screen overflow-x-hidden"
      id="site-container"
    >
      <div className="w-auto">
        <Sidebar />
      </div>
      <div className="overflow-y-none flex h-full w-full flex-col justify-start lg:items-center">
        <div
          className="z-9 w-full border-b-[1px] border-gray-400 bg-gray-50 dark:border-gray-600 dark:bg-gray-900 md:pl-6"
          id="navBar"
        >
          <NavBar />
        </div>
        <div
          className="mx-auto grid w-full max-w-[1200px] overflow-y-auto overflow-x-hidden bg-white pb-3 dark:bg-black md:mt-6 md:pb-6"
          id="manager-container"
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default ManagerProLayout;
