"use client";

import { useMutation } from "@apollo/client";
import { gqlUser } from "gql";
import { IUpdateUserNotificationTokenInput } from "gql/User/mutations";
import { createContext, ReactNode, useContext, useEffect } from "react";
import { UserState, useUserStore } from "stores/user/userStore";
import { onMessageListener, requestForToken } from "../../../firebase";

// Crear el contexto con un valor inicial de undefined
const UserStoreContext = createContext<UserState | undefined>(undefined);

// Proveedor del contexto
export const UserStoreProvider = ({ children }: { children: ReactNode }) => {
  // Llamar a `useUserStore` siempre en el mismo lugar
  const store = useUserStore();

  const [updateUserNotificationToken] = useMutation<{
    updateUserNotificationTokenInput: IUpdateUserNotificationTokenInput;
  }>(gqlUser.mutations.UPDATE_USER_NOTIFICATION_TOKEN);

  const handleGetFCMToken = async () => {
    try {
      const token = await requestForToken();
      if (token) {
        await updateUserNotificationToken({
          variables: {
            updateUserNotificationTokenInput: {
              token,
            },
          },
        });
        onMessageListener() // Escuchar mensajes en primer plano
          .then((payload: any) => {
            console.info("Mensaje recibido:", payload);
            // Aquí puedes manejar la lógica de la notificación
            // Por ejemplo, mostrar una notificación
            const notificationTitle =
              payload.notification?.title || "Sin título";
            const notificationOptions = {
              body: payload.notification?.body,
              icon: "/favicon-96x96.png",
            };
            new Notification(notificationTitle, notificationOptions);
          })
          .catch((err) => console.error("Error al escuchar mensajes:", err));
      } else {
        console.error("No se obtuvo un token de Firebase.");
      }
    } catch (error) {
      console.error("Error al obtener el token FCM:", error);
    }
  };

  useEffect(() => {
    handleGetFCMToken();
  }, []);

  return (
    <UserStoreContext.Provider value={store}>
      {children}
    </UserStoreContext.Provider>
  );
};

// Hook para usar el contexto
export const useUserStoreContext = <T,>(
  selector: (store: UserState) => T
): T => {
  const store = useContext(UserStoreContext);
  if (!store) {
    throw new Error(
      "useUserStoreContext must be used within a UserStoreProvider"
    );
  }
  return selector(store); // Aplicar el selector al store
};
